@import '../../../styles/variables';
@import '../../../styles/common';
@import '~@andes/pagination/index';
@import '~@andes/card/index';
@import '~@andes/checkbox/index';
@import '~@andes/modal/index';
@import '~@andes/tag/index';
@import '~@andes/button/index';
@import '~@andes/snackbar/index';
@import '~@andes/snackbar/lib/styles/desktop';
@import '~@andes/money-amount/index';
@import '~@andes/breadcrumb/index';
@import '~@andes/form/index';
@import '~@andes/textfield/index';
@import '~@andes/form/lib/styles/message';
@import '~@andes/tooltip/index';
@import '~@andes/dropdown/base';
@import '~@andes/dropdown/lib/styles/standalone/size/small';
@import '~@andes/dropdown/index';
@import '~@andes/list/index';
@import '~@andes/modal/base';
@import '~@andes/message/index';
@import '~@andes/message/lib/styles/neutral';
@import '~@andes/progress-indicator-circular/index';
@import '~@andes/checkbox/index';
@import '~@andes/common/lib/styles/visually-hidden';

@import '~ui-library_ml/src/components/carousel/styles/paged';
@import '~ui-library_ml/src/components/carousel/styles/arrows';
@import '../../../components/intervention/faceted-search/styles/faceted-search-intervention-desktop';
@import '../../../components-v2/icons/styles/desktop';
@import '../../../components-v2/sidebar/styles/sidebar.desktop';
@import '../../../components/sidebar/components/view-options/styles/view-options.desktop';
@import '../../../components-v2/filters/filter-sort/styles/filter-sort.pi';
@import '../../../components-v2/filters/highlighted-filter/styles/highlighted-filter.desktop';
@import '../../../components/animated-switch/styles/animated-switch';
@import '../../../components/view-change/styles/view-change.desktop';
@import '../../../components-v2/sidebar/components/breadcrumb/styles/breadcrumb.desktop';
@import '../../../components-v2/sidebar/components/map/styles/map-link.desktop.scss';
@import '../../../components-v2/sidebar/components/map/styles/map-button.desktop.scss';
@import '../../../components-v2/filters/filters-colors/styles/filtersColors.desktop';
@import '../../../components-v2/sidebar/components/filters-tooltip/styles/desktop';
@import '../../../components/tooltip/styles/tooltip.desktop.pi';
@import '../../../components-v2/sidebar/components/filters/styles/filters.desktop';
@import '../../../components/filters/range-filters/styles/range-filters.desktop';
@import '../../../components-v2/sidebar/styles/search-result.desktop';
@import '../../../components/layout/styles/layout.desktop.pi';
@import '../../../components/filters/applied/styles/applied-filters.desktop.pi';
@import '../../../components-v2/modal/styles/desktop';
@import '../../../components-v2/money-picker/styles/desktop';
@import '../../../components/pagination/styles/pagination.desktop';
@import '../../../components-v2/sidebar/components/search-modal/styles/index.scss';
@import '../../../components-v2/bookmark/styles/bookmark';
@import 'app/components/top-keywords/styles/top-keywords.desktop';
@import '../../../components/reviews/styles/reviews.desktop';
@import '../../../components-v2/sidebar/styles/dropdown.desktop.pi';
@import '../../../components/advertising/styles/advertising';
@import '../../../components-v2/carousel/styles/carousel.desktop';
@import '../../../components-v2/intervention/billboard/styles/billboard-intervention.desktop';
@import '../../../components/card/highlight/styles/highlight-label.desktop';
@import '../../../components/alert/styles/alert.desktop';
@import '../../../components-v2/header/styles/deal-header.desktop';
@import '../../../components-v2/header/styles/exhibitor-header.desktop';
@import '../../../components-v2/header/styles/official-store-header.desktop';
@import '../../../components-v2/header/styles/exhibitor-header.desktop.pi';
@import '../../../components-v2/eshops/styles/eshops.desktop';
@import '../../../components-v2/icons/svg-sprites/styles/index';

// sass-lint:disable no-vendor-prefixes, no-qualifying-elements
main[role='main'] {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;
  flex-grow: 1;
}

.#{$search-prefix} {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: visible;

  &-main {
    display: flex;
    margin-top: 32px;
    width: 100%;

    &--official-store {
      margin-top: $andes-spacing-20;
    }
  }
}

.#{$search-prefix}-top-keywords ~ .#{$search-prefix}-main {
  margin-top: 0;
}

.#{$search-prefix}-top-keywords,
.#{$search-prefix}-main {
  max-width: 1215px;
  padding-left: 10px;
  padding-right: $andes-spacing-28;
  width: 100%;
}

.#{$search-prefix}-result .#{$search-prefix}-item__highlight-label.#{$search-prefix}-item__shipping__highlight {
  font-size: $font-size-14;
  line-height: 16px;
  padding: 1px 4px 2px;
  margin: 0;
  margin-top: $andes-spacing-8;
  display: block;
}

.onboarding-cp-button {
  height: auto;
}
