@import '../../../sidebar/styles/dropdown.desktop.pi';
@import '../../../../styles/variables';

.#{$search-prefix}-sort-filter {
  display: flex;
  align-items: center;

  &__title {
    color: $andes-gray-550-solid;
    font-size: $font-size-16;
    font-weight: $font-weight-semibold;
    margin: 0 0 $andes-spacing-12;
  }

  .andes-widther {
    height: 16px;
    width: 120px;
  }

  .andes-dropdown__popover {
    box-shadow: 0 8px 16px 0 $andes-gray-100;
  }

  &__dropdown.andes-dropdown .andes-floating-menu {
    &.andes-list--default.andes-list--selectable {
      width: 130px;
    }

    .andes-dropdown__trigger {
      display: flex;
    }
  }
}

.#{$search-prefix}-results .#{$search-prefix}-sort-filter {
  margin-top: 1px;
}

.#{$search-prefix}-sort-filter-values {
  color: $andes-gray-550-solid;
  cursor: pointer;
  display: block;
  font-size: $font-size-14;
  width: 100px;
  position: relative;
  font-weight: $font-weight-regular;
}
